// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-pages-puzzle-index-jsx": () => import("./../../../src/containers/Pages/Puzzle/index.jsx" /* webpackChunkName: "component---src-containers-pages-puzzle-index-jsx" */),
  "component---src-containers-pages-puzzle-month-index-jsx": () => import("./../../../src/containers/Pages/PuzzleMonth/index.jsx" /* webpackChunkName: "component---src-containers-pages-puzzle-month-index-jsx" */),
  "component---src-containers-pages-puzzle-year-index-jsx": () => import("./../../../src/containers/Pages/PuzzleYear/index.jsx" /* webpackChunkName: "component---src-containers-pages-puzzle-year-index-jsx" */),
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-design-jsx": () => import("./../../../src/pages/about/design.jsx" /* webpackChunkName: "component---src-pages-about-design-jsx" */),
  "component---src-pages-about-story-jsx": () => import("./../../../src/pages/about/story.jsx" /* webpackChunkName: "component---src-pages-about-story-jsx" */),
  "component---src-pages-admin-generator-jsx": () => import("./../../../src/pages/admin/generator.jsx" /* webpackChunkName: "component---src-pages-admin-generator-jsx" */),
  "component---src-pages-demo-index-js": () => import("./../../../src/pages/demo/index.js" /* webpackChunkName: "component---src-pages-demo-index-js" */),
  "component---src-pages-guides-chords-jsx": () => import("./../../../src/pages/guides/chords.jsx" /* webpackChunkName: "component---src-pages-guides-chords-jsx" */),
  "component---src-pages-guides-strategy-jsx": () => import("./../../../src/pages/guides/strategy.jsx" /* webpackChunkName: "component---src-pages-guides-strategy-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-index-jsx": () => import("./../../../src/pages/links/index.jsx" /* webpackChunkName: "component---src-pages-links-index-jsx" */),
  "component---src-pages-rules-fun-jsx": () => import("./../../../src/pages/rules/fun.jsx" /* webpackChunkName: "component---src-pages-rules-fun-jsx" */),
  "component---src-pages-rules-playing-jsx": () => import("./../../../src/pages/rules/playing.jsx" /* webpackChunkName: "component---src-pages-rules-playing-jsx" */),
  "component---src-pages-rules-rotating-jsx": () => import("./../../../src/pages/rules/rotating.jsx" /* webpackChunkName: "component---src-pages-rules-rotating-jsx" */),
  "component---src-pages-rules-scoring-jsx": () => import("./../../../src/pages/rules/scoring.jsx" /* webpackChunkName: "component---src-pages-rules-scoring-jsx" */),
  "component---src-pages-rules-setup-jsx": () => import("./../../../src/pages/rules/setup.jsx" /* webpackChunkName: "component---src-pages-rules-setup-jsx" */)
}

