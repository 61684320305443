export const PLAYER_SITA = 'Sita'
export const PLAYER_BENNETT = 'Bennett'
export const PLAYER_CATHERINE = 'Catherine'
export const PLAYER_KHARI = 'Khari'
export const PLAYERS = [
    PLAYER_SITA,
    PLAYER_BENNETT,
    PLAYER_CATHERINE,
    PLAYER_KHARI,
]
export const PLAYERS_COUNT = PLAYERS.length

export const DEMO_BOARD_HEXAGON_WIDTH = 12
