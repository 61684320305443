module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BY16GDRCV4"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bobtail Dominoes","short_name":"Bobtail Dominoes","description":"Website for the musical tile game Bobtail Dominoes.","start_url":"/","background_color":"#6f939b","theme_color":"#ead9cc","display":"standalone","icon":"src/assets/favicon/bobtailDominoes.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5c5421ca859ba88c2c4280ade12dc259"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
